import { useCallback, useEffect, useRef, useState } from 'react';
export function usePagination(props) {
    const [pageIndex, setPageIndex] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const previousPageIndex = useRef(null);
    useEffect(() => {
        previousPageIndex.current = null;
    }, [numberOfPages]);
    const incrementPageIndex = useCallback(() => {
        if (!hasMorePages)
            return;
        previousPageIndex.current = pageIndex;
        setPageIndex((currentIndex) => currentIndex + 1);
    }, [hasMorePages, pageIndex]);
    const decrementPageIndex = useCallback(() => {
        previousPageIndex.current = pageIndex;
        setPageIndex((currentIndex) => currentIndex > 0 ? currentIndex - 1 : currentIndex);
    }, [pageIndex]);
    const changePageIndex = useCallback((newIndex) => {
        if (newIndex >= 0 && newIndex < numberOfPages) {
            previousPageIndex.current = pageIndex;
            setPageIndex(newIndex);
        }
    }, [numberOfPages, pageIndex]);
    const changeNumberOfPages = useCallback((newValue) => {
        if (newValue < 0)
            return;
        if (newValue === numberOfPages)
            return;
        setPageIndex(0);
        setNumberOfPages(newValue);
        setHasMorePages(newValue > 0);
    }, [numberOfPages]);
    const switchPageIndexToThePreviuosOne = useCallback(() => {
        if (previousPageIndex.current !== null) {
            setPageIndex(previousPageIndex.current);
        }
    }, []);
    const updateInfosFromResponse = useCallback((response) => {
        setHasMorePages((currentValue) => {
            return response?.data ? response.data.hasNextPage : currentValue;
        });
        setNumberOfPages((currentValue) => {
            return response?.data
                ? Math.ceil(response.data.totalCount / props.pageSize)
                : currentValue;
        });
    }, [props.pageSize]);
    return {
        pageIndex,
        hasMorePages,
        numberOfPages,
        incrementPageIndex,
        decrementPageIndex,
        setPageIndex: changePageIndex,
        setNumberOfPages: changeNumberOfPages,
        updateInfosFromResponse,
        switchPageIndexToThePreviuosOne
    };
}

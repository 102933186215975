import styled from 'styled-components';
export const ContainerUpload = styled.div `
  display: contents;

  & > :first-child {
    width: 600px;
    height: 154px;

    background: white;
    border-color: ${({ theme }) => theme.colors.gray200};
  }
`;

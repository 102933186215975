import React from 'react';
import { Page } from '../commons/components';
import { LiteraryWorkPageContext } from './context';
import { WorkDetails, TabSelector } from './components';
import { useFetchLiteraryWork, useLiteraryWorkId, useTabs } from './hooks';
import { WorkDetailsAndTabsContainer } from './styles';
const LiteraryWork = ({ title, isReadOnlyOnGeneral, questionsCanBe, allowAccessToAnUnpublishedWork }) => {
    const literaryWorkId = useLiteraryWorkId();
    const { data, requestStatus } = useFetchLiteraryWork({ literaryWorkId });
    const { selectedTab, setSelectedTab, selectedTabComponent } = useTabs();
    return (React.createElement(Page, { pageTitle: title },
        React.createElement(LiteraryWorkPageContext.Provider, { value: {
                literaryWorkId,
                literaryWork: { data, status: requestStatus },
                isReadOnlyOnGeneral: isReadOnlyOnGeneral ?? false,
                questionsCanBe: questionsCanBe ?? 'managed',
                allowAccessToAnUnpublishedWork: allowAccessToAnUnpublishedWork ?? false
            } },
            React.createElement(WorkDetailsAndTabsContainer, null,
                React.createElement(WorkDetails, null),
                React.createElement(TabSelector, { selectedTab: selectedTab, onTabChange: setSelectedTab })),
            selectedTabComponent)));
};
export default LiteraryWork;

export default class Event {
    subscribers;
    constructor() {
        this.subscribers = [];
    }
    unsubscribe(handler) {
        const index = this.subscribers.indexOf(handler);
        if (index !== -1) {
            this.subscribers.splice(index, 1);
        }
    }
    subscribe(handler) {
        if (!this.subscribers.includes(handler)) {
            this.subscribers.push(handler);
        }
        return () => {
            this.unsubscribe(handler);
        };
    }
    emit(payload) {
        for (const handler of this.subscribers) {
            handler(payload);
        }
    }
}

import { css } from 'styled-components';
export const commonCardStyles = `
  height: 100%;

  border: none;
  box-shadow: none;

  & > :last-child {
    flex-grow: 1;
  }

  .ck-content .image {
    width: auto;
  }

  .ck-content .image img {
    min-width: 0;
  }

  .ck-content .image,
  .ck.ck-editor__editable_inline > :first-child.image {
    margin: 0 auto 20px auto;
  }

  .ck.ck-editor__editable_inline {
    padding: 0;
  }
`;
export const commonEditorFontStyles = `
  color: #4D4D4D;

  font-size: 14px;
  font-weight: 600;

  line-height: 20px;
`;
export const commonOptionsBoxStyles = css `
  input[type='radio']:checked,
  input[type='checkbox']:checked {
    background-color: white;
    border: 5px solid ${({ theme }) => theme.colors.blue1};
  }
`;
export const commonCommentBoxStyles = `
  padding: 10px 14px 14px 14px;

  h3 {
    margin-bottom: 5px;
  }

  .ck.ck-editor__editable_inline p {
    font-size: 12px;
    font-weight: 600;

    line-height: 16px;
  }

  .ck.ck-editor__editable_inline > :first-child {
    margin-top: 0;
  }
`;

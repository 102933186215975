import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
const DOMAIN = process.env.REACT_APP_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const AUDIENCE = process.env.REACT_APP_AUDIENCE;
const SCOPE = process.env.REACT_APP_SCOPE;
export const getAuth0AuthConfig = () => {
    return {
        domain: DOMAIN,
        clientId: CLIENT_ID,
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience: AUDIENCE,
            scope: SCOPE
        }
    };
};
export const useAuth0Authorization = () => {
    const { isAuthenticated, isLoading, error, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
    useEffect(() => {
        if (isLoading || isAuthenticated)
            return;
        loginWithRedirect({ appState: { returnTo: window.location.href } });
    }, [isLoading, isAuthenticated, loginWithRedirect]);
    const getAccessToken = () => getAccessTokenSilently();
    const handleLogout = async () => {
        await logout({ logoutParams: { returnTo: window.location.href } });
    };
    return {
        isAuthenticated,
        loading: isLoading,
        error: !!error,
        getAccessToken: getAccessToken,
        logout: handleLogout
    };
};

import React, { useRef, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import CloseIcon from '@campus/components/assets/icons/close.svg';
import PlusIcon from '@campus/components/assets/icons/plus-tags.svg';
import { ButtonAddTag, Container, Content, InvisibleInput, Item, LabelTag, Suggestions } from './styles';
const TagsInput = ({ className, items, onAdd, onRemove, itemsForSearch, containerRef, max, label, addTagButtonText }) => {
    const [inputText, setInputText] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const inputRef = useRef(null);
    const onClickAdd = () => {
        setIsAdding(true);
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current?.click();
                inputRef.current?.focus();
            }
        }, 200);
    };
    const handleClickAdd = (item) => {
        onAdd(item);
        setInputText('');
        inputRef.current.focus();
    };
    const onKeyDownInput = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (inputText.length > 0) {
                handleClickAdd(inputText);
            }
        }
    };
    const itemsSearched = itemsForSearch
        ?.filter((item) => item.toLowerCase().includes(inputText.toLowerCase()))
        ?.slice(0, 20);
    return (React.createElement(Container, { className: className },
        label && React.createElement(LabelTag, { className: "tags-input__label" }, label),
        React.createElement(Content, { className: "tags-input__tags" },
            items.map((item) => (React.createElement(Item, { key: item.value, className: "tags-input__tag" },
                item.label,
                React.createElement("button", { "aria-label": "excluir tag", onClick: () => onRemove(item.value) },
                    React.createElement("img", { src: CloseIcon, alt: "" }))))),
            (!max || items.length < max) && (React.createElement(React.Fragment, null,
                React.createElement(OverlayTrigger, { rootClose: true, trigger: "click", placement: "auto-start", container: containerRef?.current, onExited: () => setIsAdding(false), popperConfig: {
                        modifiers: [
                            {
                                name: 'flip',
                                enabled: true,
                                options: {
                                    fallbackPlacements: ['top', 'bottom'],
                                    allowedAutoPlacements: ['top-start', 'bottom-start']
                                }
                            },
                            { name: 'offset', options: { offset: [0, 6] } }
                        ]
                    }, overlay: React.createElement(Suggestions, { className: "tags-input__suggestions" },
                        React.createElement("ul", null,
                            itemsSearched.map((item) => (React.createElement("li", { key: item },
                                React.createElement("button", { key: item, onClick: () => {
                                        handleClickAdd(inputText);
                                    } }, item)))),
                            !itemsForSearch?.length && (React.createElement("li", { key: inputText },
                                React.createElement("button", { key: inputText, onClick: () => {
                                        handleClickAdd(inputText);
                                    } },
                                    "Adicionar \"",
                                    inputText,
                                    "\""))))), show: isAdding && inputText.length > 0 },
                    React.createElement(InvisibleInput, { className: "tags-input__input", ref: inputRef, style: isAdding ? {} : { display: 'none' }, value: inputText, name: "search-tag", onChange: (e) => setInputText(e.target.value), onKeyDown: onKeyDownInput })),
                !isAdding && (React.createElement(ButtonAddTag, { className: "tags-input__add-tag", onClick: onClickAdd },
                    React.createElement("img", { src: PlusIcon, alt: "\u00EDcone adicionar tag", "aria-hidden": true }),
                    ' ',
                    addTagButtonText || 'Adicionar tag')))))));
};
export default TagsInput;

import styled from 'styled-components';
export const ContentDeleteLiteraryWorksBody = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 34px;
`;
export const LabelDeleteLiteraryWorks = styled.span `
  font-style: normal;
  font-size: 20px;
  font-weight: ${(props) => props.theme.weight.semiBold};
  line-height: 100%;
  margin-top: 8px;
  text-align: center;
  color: ${(props) => props.theme.colors.gray800};
  width: 265px;
`;
export const SubLabelDeleteLiteraryWorks = styled.span `
  text-align: center;
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 14px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.gray3};
  margin-bottom: 16px;
  margin-top: 7px;
`;

import React from 'react';
import { RequestStatus } from '@campus/commons';
import { EditQuestionModal as BaseEditModal } from '@campus/content-items';
import { LiteraryWorkQuestion } from '../../../../../../models';
import { isUploadComplete, updateQuestion as updateEndpoint } from '../../../../../../services';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useQuestionsTabContext } from '../../context';
import { useFetchQuestion, useQuestionActionFeedbacks, useUploadForQuestion } from '../../hooks';
import { commentBoxInfos } from '../../commons/commentBox';
import { questionValidationSchema } from './validationSchema';
import { allowChangingQuestionKind } from './utils';
import { Modal } from './styles';
const emptyQuestion = new LiteraryWorkQuestion();
const EditQuestionModal = ({ questionId, hideModal }) => {
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { events, questionSummaries } = useQuestionsTabContext();
    const { data: question, requestStatus } = useFetchQuestion({
        literaryWorkId,
        questionId
    });
    const { createUploadInstance } = useUploadForQuestion({
        literaryWorkId,
        questionId
    });
    const feedbacks = useQuestionActionFeedbacks();
    function updateQuestion(updatedQuestion) {
        return updateEndpoint({
            questionId,
            items: updatedQuestion.items,
            answers: updatedQuestion.answers,
            comment: updatedQuestion.comment ?? '',
            questionType: updatedQuestion.questionType
        });
    }
    function handleQuestionUpdatedSuccessfully(updatedQuestion) {
        events.aQuestionWasUpdated.emit({ updatedQuestion });
        feedbacks.questionWasUpdated(questionId);
        hideModal();
    }
    const canChangeQuestionKind = allowChangingQuestionKind({
        questionSummaries,
        questionId
    });
    return (React.createElement(Modal, { show: true, size: "lg", onHide: hideModal, enforceFocus: false },
        React.createElement(BaseEditModal, { submitButtonText: "Editar Quest\u00E3o", question: question ?? emptyQuestion, allowItemsToBeMovedRegardlessOfTheirStatus: true, showSkeleton: requestStatus === RequestStatus.Loading, doNotAllowChangingQuestionType: !canChangeQuestionKind, validationSchema: questionValidationSchema, commentBoxContent: () => commentBoxInfos, hide: [
                'disciplineSelect',
                'gradeSelect',
                'applyingInstitution',
                'sourceSelector',
                'classifications',
                'openQuestionBox'
            ], onClose: hideModal, updateQuestion: updateQuestion, handleQuestionUpdatedSuccessfully: handleQuestionUpdatedSuccessfully, createItemsRelatedUploadInstance: createUploadInstance, createQuestionRelatedUploadInstance: createUploadInstance, isUploadComplete: (uploadUrl) => isUploadComplete({ uploadUrl }) })));
};
export default EditQuestionModal;

import { useCallback, useEffect } from 'react';
import { useFetch, usePagination } from '@campus/commons';
import { getAllLiteraryWorks } from '../../../services';
export function useFetchLiteraryWorks(props) {
    const { pageSize, skipFetch } = props;
    const { pageIndex, numberOfPages, setPageIndex, updateInfosFromResponse } = usePagination({ pageSize });
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchLiteraryWorks = useCallback(async () => {
        fetchStarted();
        try {
            const response = await getAllLiteraryWorks({
                pageSize,
                pageIndex
            });
            handleSuccess(response?.data?.items);
            updateInfosFromResponse(response);
        }
        catch (error) {
            handleFailure(error);
        }
    }, [
        pageIndex,
        fetchStarted,
        handleFailure,
        handleSuccess,
        updateInfosFromResponse,
        pageSize
    ]);
    useEffect(() => {
        if (!skipFetch) {
            fetchLiteraryWorks();
        }
    }, [fetchLiteraryWorks, skipFetch]);
    return {
        data,
        requestStatus,
        numberOfPages,
        pageIndex,
        setPageIndex,
        fetchLiteraryWorks
    };
}

import { useCallback, useEffect, useState } from 'react';
import { toast } from '@campus/components';
import { addVideoAnalysisItems, deleteVideoAnalysisItems, updateVideoAnalysisItems } from '../../../../../../services';
import { errorMessages } from './errors';
import { getValidationErrorsFrom } from './utils';
export function useVideoManager(props) {
    const { items: initialItems, literaryWorkId, onError } = props;
    const [items, setItems] = useState(initialItems);
    const [mainVideo, setMainVideo] = useState(items.at(0));
    useEffect(() => {
        if (initialItems) {
            setItems(initialItems);
            setMainVideo(initialItems.at(0));
        }
    }, [initialItems]);
    const handleError = useCallback((error, fallbackMessage) => {
        const errors = getValidationErrorsFrom(error);
        onError(errors.length > 0 ? errors : fallbackMessage);
    }, [onError]);
    const addVideo = useCallback(async (url, title) => {
        try {
            const response = await addVideoAnalysisItems({
                url,
                title,
                literaryWorkId
            });
            const newItem = response.data;
            if (!newItem) {
                onError(errorMessages.addIsMissingData);
                return;
            }
            setItems((currentValue) => {
                return [...currentValue, newItem];
            });
            if (!mainVideo) {
                setMainVideo(newItem);
            }
            toast.success('Vídeo adicionado com sucesso!');
        }
        catch (error) {
            handleError(error, errorMessages.addFailed);
        }
    }, [literaryWorkId, mainVideo, onError, handleError]);
    const removeVideo = useCallback(async (videoId) => {
        try {
            await deleteVideoAnalysisItems({
                literaryWorkId,
                videoId
            });
            setItems((currentValue) => {
                return currentValue.filter((i) => {
                    return i.id !== videoId;
                });
            });
            if (videoId === mainVideo?.id) {
                setMainVideo(items[0]?.id === videoId ? items[1] : items[0]);
            }
            toast.success('Vídeo excluído com sucesso!');
        }
        catch (error) {
            handleError(error, errorMessages.removeFailed);
        }
    }, [handleError, literaryWorkId, items, mainVideo]);
    const updateVideo = useCallback(async (item) => {
        try {
            const response = await updateVideoAnalysisItems({
                literaryWorkId,
                item
            });
            const updatedItem = response.data;
            if (!updatedItem) {
                onError(errorMessages.updateIsMissingData);
                return;
            }
            setItems((currentValue) => {
                return currentValue.map((i) => {
                    return i.id === updatedItem.id ? updatedItem : i;
                });
            });
            if (mainVideo?.id === updatedItem.id) {
                setMainVideo(updatedItem);
            }
            toast.success('Vídeo atualizado com sucesso!');
        }
        catch (error) {
            handleError(error, errorMessages.updateFailed);
        }
    }, [handleError, literaryWorkId, mainVideo, onError]);
    const updateMainVideo = (video) => {
        setMainVideo(video);
    };
    return {
        items,
        addVideo,
        removeVideo,
        updateVideo,
        updateMainVideo,
        mainVideo
    };
}

import React from 'react';
import { Modal } from '@campus/components';
import { StyledButton, StyledCancelButton, StyledCheckbox, StyledLeftCol, StyledModalBody, StyledModal, StyledRightCol, StyledRow, StyledTextarea, StyledInput, StyledTagsInput, StyledDropImage } from './styles';
import { useModalLiteraryWork } from './hooks/useModalLiteraryWork';
const ModalLiteraryWork = ({ literaryWork, onHide, onSaved, show }) => {
    const { literaryWorkState, tags, coverImageFileUrl, validatedFields, loading, onChange, handleChangeImage, onRemoveRelatedVestibular, onAddRelatedVestibular, onSave, handleHide } = useModalLiteraryWork(onHide, onSaved, literaryWork);
    return (React.createElement(StyledModal, { show: show, onHide: handleHide, size: "xl" },
        React.createElement(Modal.Header, null,
            React.createElement(Modal.Title, null,
                literaryWork?.id ? 'Editar' : 'Adicionar',
                " Obra Liter\u00E1ria")),
        React.createElement(StyledModalBody, null,
            React.createElement(StyledRow, null,
                React.createElement(StyledLeftCol, null,
                    React.createElement(StyledInput, { type: "text", name: "literary-work-display-time", label: "Nome da Obra", placeholder: "Ex.: Dom Casmurro", value: literaryWorkState?.name ?? '', onChange: (e) => onChange('name', e.target.value), isError: !validatedFields && !literaryWorkState?.name, errorMessage: 'O nome da obra é obrigatório', required: true }),
                    React.createElement(StyledInput, { type: "text", name: "literary-work-author", label: "Autor", placeholder: "Ex.: Machado de Assis", value: literaryWorkState?.author ?? '', onChange: (e) => onChange('author', e.target.value), isError: !validatedFields && !literaryWorkState?.author, errorMessage: "O nome do autor \u00E9 obrigat\u00F3rio", required: true }),
                    React.createElement(StyledCheckbox, { name: "literary-work-published", label: "Publicada", onChange: (e) => onChange('published', e.target.checked), checked: literaryWorkState?.published ?? false }),
                    React.createElement(StyledTagsInput, { items: tags, itemsForSearch: [], onAdd: onAddRelatedVestibular, onRemove: onRemoveRelatedVestibular, label: "Vestibulares Relacionados", addTagButtonText: "Adicionar vestibular" }),
                    React.createElement(StyledTextarea, { name: "literary-work-short-text", label: "Breve Texto", placeholder: "Descreva brevemente a obra liter\u00E1ria", value: literaryWorkState?.shortText ?? '', onChange: (e) => onChange('shortText', e.target.value) })),
                React.createElement(StyledRightCol, null,
                    React.createElement(StyledDropImage, { imageUrl: coverImageFileUrl, onChange: handleChangeImage, label: "Adicionar Capa" })))),
        React.createElement(Modal.Footer, null,
            React.createElement(StyledCancelButton, { onClick: handleHide }, "Cancelar"),
            React.createElement(StyledButton, { onClick: onSave, loading: loading },
                literaryWork?.id ? 'Editar' : 'Adicionar',
                " obra"))));
};
export default ModalLiteraryWork;

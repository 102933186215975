import React from 'react';
import { Container, PageNumber, PageNumbersContainer, ButtonText, PreviousPageButton, NextPageButton, ArrowLeftIcon, ArrowRightIcon } from './styles';
const PageController = ({ className, numberOfPages, pageIndex, setPageIndex, hidePreviousAndNextButtonsIfDisabled }) => {
    function onPageNumbersContainerClick(event) {
        if (event.target instanceof HTMLElement && event.target.dataset.index) {
            setPageIndex(+event.target.dataset.index);
        }
    }
    function renderPageNumbers() {
        const children = Array.from({ length: numberOfPages });
        for (let i = 0; i < numberOfPages; ++i) {
            const isSelected = pageIndex === i;
            children[i] = (React.createElement(PageNumber, { key: i, "data-index": i, disabled: numberOfPages === 1, "$isSelected": isSelected, className: 'page-selector__page-numbers__item' +
                    (isSelected ? ' selected' : '') }, i + 1));
        }
        return children;
    }
    return (React.createElement(Container, { className: className },
        React.createElement(PreviousPageButton, { disabled: pageIndex === 0, onClick: () => setPageIndex(pageIndex - 1), className: 'page-selector__go-to-previous-page' +
                (hidePreviousAndNextButtonsIfDisabled ? ' hide-if-disabled' : '') },
            React.createElement(ArrowLeftIcon, null),
            React.createElement(ButtonText, null, "Anterior")),
        React.createElement(PageNumbersContainer, { className: "page-selector__page-numbers", onClick: onPageNumbersContainerClick }, renderPageNumbers()),
        React.createElement(NextPageButton, { disabled: pageIndex === numberOfPages - 1, onClick: () => setPageIndex(pageIndex + 1), className: 'page-selector__go-to-next-page' +
                (hidePreviousAndNextButtonsIfDisabled ? ' hide-if-disabled' : '') },
            React.createElement(ButtonText, null, "Pr\u00F3xima"),
            React.createElement(ArrowRightIcon, null))));
};
export default PageController;

import React, { useState } from 'react';
import { RequestStatus, useStudentId } from '@campus/commons';
import { answerQuestion } from '../../../../../../services';
import { useLiteraryWorkPageContext } from '../../../../context';
import { times } from '../../../../commons/times';
import { useQuestionsTabContext } from '../../context';
import { Container, Text } from './styles';
const statusAndButtonTexts = {
    [RequestStatus.NotStarted]: 'Responder',
    [RequestStatus.Loading]: 'Salvando resposta...',
    [RequestStatus.Succeeded]: 'Resposta salva!',
    [RequestStatus.Failed]: 'Falha ao salvar resposta',
    [RequestStatus.NotAllowed]: 'Sem permissão para responder'
};
const AnswerQuestionButton = ({ contentItem, handleQuestionAnsweredSuccessfully }) => {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const studentId = useStudentId();
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { selectedQuestionId } = useQuestionsTabContext();
    const disabled = !selectedQuestionId ||
        !contentItem.data ||
        requestStatus !== RequestStatus.NotStarted;
    async function onClick() {
        setRequestStatus(RequestStatus.Loading);
        try {
            await answerQuestion({
                studentId,
                literaryWorkId,
                questionId: selectedQuestionId,
                questionData: contentItem.data
            });
            setRequestStatus(RequestStatus.Succeeded);
            handleQuestionAnsweredSuccessfully();
        }
        catch {
            setRequestStatus(RequestStatus.Failed);
            setTimeout(() => {
                setRequestStatus(RequestStatus.NotStarted);
            }, times.toResetRequestStatusAfterFailure);
        }
    }
    return (React.createElement(Container, { disabled: disabled, onClick: disabled ? undefined : onClick },
        React.createElement(Text, null, statusAndButtonTexts[requestStatus])));
};
export default AnswerQuestionButton;

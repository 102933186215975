import { useCallback, useRef, useState } from 'react';
import { axios, Response } from '..';
import { RequestStatus } from '../types';
export function useFetch(props) {
    const [data, setData] = useState(props?.initialData);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const initialDataRef = useRef(props?.initialData);
    const fetchStarted = useCallback((options) => {
        if (options?.resetData) {
            setData(initialDataRef.current);
        }
        setRequestStatus(RequestStatus.Loading);
    }, []);
    const handleSuccess = useCallback((dataOrResponse) => {
        if (dataOrResponse instanceof Response) {
            setData(dataOrResponse.data);
        }
        else if (dataOrResponse) {
            setData(dataOrResponse);
        }
        setRequestStatus(RequestStatus.Succeeded);
    }, []);
    const handleFailure = useCallback((error, options) => {
        let nativeError;
        const errorIsResponse = error instanceof Response;
        if (error instanceof Error) {
            nativeError = error;
        }
        else if (errorIsResponse) {
            nativeError = error.error?.error;
        }
        if (nativeError && axios.isCancel(nativeError))
            return;
        if (options?.resetData) {
            setData(initialDataRef.current);
        }
        if (errorIsResponse && error.statusCode === 403) {
            setRequestStatus(RequestStatus.NotAllowed);
        }
        else {
            setRequestStatus(RequestStatus.Failed);
        }
    }, []);
    const resetData = useCallback(() => {
        setData(initialDataRef.current);
    }, []);
    const resetStatus = useCallback((options) => {
        function reset() {
            if (!options?.condition || options.condition()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }
        if (Number.isInteger(options?.afterMillis)) {
            setTimeout(reset, options.afterMillis);
        }
        else {
            reset();
        }
    }, []);
    return {
        data,
        requestStatus,
        fetchStarted,
        resetStatus,
        resetData,
        handleFailure,
        handleSuccess
    };
}

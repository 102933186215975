import React from 'react';
import styled from 'styled-components';
import { formatAsPercentage } from '../../../utils';
const Content = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    width: 2.6rem;
    margin: 0;
  }

  span {
    font-size: 12px !important;
    font-weight: 600;
    text-align: center;
  }
`;
const Box = styled.div `
  display: flex;
  height: 12px;
  flex: 1;
  width: 50px;
  min-width: 50px;
  box-shadow: 0 0 0px 1px ${(props) => props.theme.colors.gray300} inset;
  border-radius: 2px;
  overflow: hidden;
  background-color: white;
`;
const Item = styled.div `
  height: 100%;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) => props.color ?? props.theme.colors.gray300};

  :last-child {
    border-radius: 0;
  }
`;
const PercentageContainer = ({ total, percentages, showTotal = true }) => {
    if (percentages.length === 0) {
        return React.createElement("span", null, "Sem atividades");
    }
    return (React.createElement(Content, null,
        React.createElement(Box, null, percentages.map((p, i) => (React.createElement(Item, { key: i, ...p })))),
        React.createElement("span", null, showTotal ? formatAsPercentage(total) : '-')));
};
export default PercentageContainer;

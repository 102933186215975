import styled from 'styled-components';
import Card from '../Card';
export const Container = styled(Card) `
  position: relative;

  ${(props) => (props.$isClickable ? 'cursor: pointer;' : '')}
`;
export const Image = styled.img `
  width: 100%;
  height: 100%;

  object-fit: cover;

  border-radius: inherit;

  ${(props) => (props.$blur ? 'opacity: 0.4;' : '')}
`;
export const NoCoverImage = styled.div `
  height: 100%;

  display: flex;
  row-gap: 6px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: lightgray 0px 0px / 100% 100% no-repeat;

  > * {
    z-index: 2;
  }

  svg {
    display: block;
    color: #9decf9;
  }

  span {
    color: #9decf9;

    font-size: 14px;
    font-weight: 700;

    line-height: 100%;

    text-align: center;
  }

  &::before {
    content: '';

    z-index: 1;

    position: absolute;
    inset: 0;

    border-radius: 4px;

    mix-blend-mode: difference;
    background: linear-gradient(313deg, #b7295a 0%, #ff6a00 100%);
  }
`;

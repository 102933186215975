import React from 'react';
import { CampusIcon } from '@campus/components';
import { Container, Image, NoCoverImage } from './styles';
const WorkCoverImage = (props) => {
    const { children, literaryWork, onClick, doNotBlurIfWorkIsUnpublished } = props;
    const { coverImageUrl, published } = literaryWork;
    return (React.createElement(Container, { ...(onClick ? { onClick, $isClickable: true } : {}) },
        coverImageUrl ? (React.createElement(Image, { src: coverImageUrl, "$blur": !published && !doNotBlurIfWorkIsUnpublished })) : (React.createElement(NoCoverImage, null,
            React.createElement(CampusIcon, { name: "letterCFromLogo" }),
            React.createElement("span", null, "Sem Capa"))),
        children));
};
export default WorkCoverImage;

import React, { useCallback } from 'react';
import { QuestionType } from '@campus/commons';
import { CreateQuestionModal as BaseCreateModal } from '@campus/content-items';
import { LiteraryWorkQuestion, LiteraryWorkQuestionKind, questionTypeToQuestionKind } from '../../../../../../models';
import { isUploadComplete, addQuestion as addEndpoint } from '../../../../../../services';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useUploadForLiteraryWork } from '../../../../hooks';
import { useQuestionsTabContext } from '../../context';
import { useQuestionActionFeedbacks } from '../../hooks';
import { commentBoxInfos } from '../../commons/commentBox';
import { questionValidationSchema } from './validationSchema';
import { allowChangingQuestionKind } from './utils';
import { Modal as Modal } from './styles';
const kindsAndDefaultTypes = {
    [LiteraryWorkQuestionKind.Objective]: QuestionType.UNIQUE,
    [LiteraryWorkQuestionKind.Discursive]: QuestionType.OPEN
};
const CreateQuestionModal = ({ questionKind, hideModal }) => {
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { events, questionSummaries } = useQuestionsTabContext();
    const { createUploadInstance } = useUploadForLiteraryWork({ literaryWorkId });
    const feedbacks = useQuestionActionFeedbacks();
    const createEmptyQuestion = useCallback(() => {
        const question = new LiteraryWorkQuestion();
        question.questionType = kindsAndDefaultTypes[questionKind];
        question.kind = questionTypeToQuestionKind(question.questionType);
        return question;
    }, [questionKind]);
    function addQuestion(question) {
        return addEndpoint({
            literaryWorkId,
            items: question.items,
            answers: question.answers,
            comment: question.comment ?? '',
            questionType: question.questionType
        });
    }
    function handleQuestionCreatedSuccessfully(question) {
        events.aQuestionWasCreated.emit();
        feedbacks.questionWasCreated(question.questionType);
        hideModal();
    }
    const canChangeQuestionKind = allowChangingQuestionKind({
        questionSummaries,
        questionKind
    });
    return (React.createElement(Modal, { show: true, size: "lg", onHide: hideModal, enforceFocus: false },
        React.createElement(BaseCreateModal, { submitButtonText: "Adicionar Quest\u00E3o", allowItemsToBeMovedRegardlessOfTheirStatus: true, validationSchema: questionValidationSchema, commentBoxContent: () => commentBoxInfos, doNotAllowChangingQuestionType: !canChangeQuestionKind, hide: [
                'disciplineSelect',
                'gradeSelect',
                'applyingInstitution',
                'sourceSelector',
                'classifications',
                'openQuestionBox'
            ], onClose: hideModal, createQuestion: addQuestion, createEmptyQuestion: createEmptyQuestion, handleQuestionCreatedSuccessfully: handleQuestionCreatedSuccessfully, createItemsRelatedUploadInstance: createUploadInstance, createQuestionRelatedUploadInstance: createUploadInstance, isUploadComplete: (uploadUrl) => isUploadComplete({ uploadUrl }) })));
};
export default CreateQuestionModal;

import { Api } from '@campus/auth';
import { PaginationModel, Response, Upload, UploadResult } from '@campus/commons';
import { endpointBasePath } from './common';
import { LiteraryWork } from '../models';
const apiEndpoints = {
    get: {
        all: endpointBasePath,
        byId(id) {
            return `${endpointBasePath}/${id}`;
        }
    },
    create: endpointBasePath,
    upload({ literaryWorkId, whatToUpload }) {
        return `${endpointBasePath}/${literaryWorkId}/upload/${whatToUpload}`;
    }
};
const allowedUploadTypes = [
    'image',
    'audio',
    'document',
    'video'
];
export async function getAllLiteraryWorks(params) {
    try {
        const skipCount = params.pageSize * params.pageIndex;
        const response = await Api.INSTANCE.withoutErrorHandlerInterceptor((axiosInstance) => {
            return axiosInstance.get(apiEndpoints.get.all, {
                params: { MaxResultCount: params.pageSize, SkipCount: skipCount }
            });
        });
        const paginated = PaginationModel.fromJson(LiteraryWork, response.data ?? [], skipCount);
        return Response.fromData(paginated, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function getLiteraryWorkById(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.get.byId(params.id);
        const response = await axiosInstance.get(endpoint);
        const item = LiteraryWork.fromJson(response.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function createLiteraryWork(literaryWork) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.post(apiEndpoints.create, literaryWork);
        return Response.fromData(LiteraryWork.fromJson(response.data), response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function uploadForLiteraryWork(params) {
    if (!allowedUploadTypes.includes(params.whatToUpload)) {
        return Response.fromError(new Error('Tipo inválido.'));
    }
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.upload(params);
        const response = await axiosInstance.put(endpoint, {
            Provider: params.provider,
            FileName: params.fileName,
            FileSize: params.fileSize
        });
        return Response.fromData(UploadResult.fromJson(response.data, params.provider), response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
async function uploadImageAndGetUrl(file) {
    try {
        const upload = new Upload('content', { type: 'image' });
        await upload.attrFile(file);
        await upload.exec();
        return upload.getViewUrl();
    }
    catch {
        throw new Error('Failed to upload image');
    }
}
export async function uploadCoverImage(literaryWork, file) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const imageUrl = await uploadImageAndGetUrl(file);
        literaryWork.coverImageUrl = imageUrl;
        const response = await axiosInstance.put(`${endpointBasePath}/${literaryWork.id}`, literaryWork);
        return Response.fromData(response.data, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function updateLiteraryWork(literaryWork) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.put(`${endpointBasePath}/${literaryWork.id}`, literaryWork);
        return Response.fromData(LiteraryWork.fromJson(response.data), response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function deleteLiteraryWorkById(id) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        await axiosInstance.delete(`${endpointBasePath}/${id}`);
    }
    catch (error) {
        throw Response.error(error);
    }
}

import axios from 'axios';
import { Context, Response } from '../models';
class Api {
    api;
    static INSTANCE;
    url;
    token;
    errorHandler;
    context;
    errorHandlerResponseInterceptor = null;
    constructor(props) {
        const { errorHandler, getAccessToken, url } = props;
        this.context = Context.getLocalContext();
        this.api = axios.create({ baseURL: url, withCredentials: true });
        this.api.interceptors.request.use(this.contextInterceptor.bind(this));
        this.api.interceptors.request.use(async (config) => {
            const token = await getAccessToken();
            if (token)
                config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
        this.errorHandler = errorHandler;
        this.addErrorHandlerInterceptor();
    }
    addErrorHandlerInterceptor() {
        this.errorHandlerResponseInterceptor = this.api.interceptors.response.use((r) => r, (e) => {
            this.errorHandler(Response.fromAxiosError(e));
            throw e;
        });
    }
    removeErrorHandlerInterceptor() {
        if (this.errorHandlerResponseInterceptor === null)
            return;
        this.api.interceptors.response.eject(Api.INSTANCE.errorHandlerResponseInterceptor);
    }
    static createInstance(url, getAccessToken, errorHandler) {
        Api.INSTANCE = new Api({ url, getAccessToken, errorHandler });
    }
    getApi() {
        return this.api;
    }
    async withoutErrorHandlerInterceptor(runnable) {
        this.removeErrorHandlerInterceptor();
        const result = await runnable(this.api);
        this.addErrorHandlerInterceptor();
        return result;
    }
    updateContext(context) {
        this.context = context;
        this.api.interceptors.request.use(this.contextInterceptor.bind(this));
    }
    contextInterceptor(config) {
        if (this.context != null) {
            config.headers.TenantId = this.context.tenantId;
            config.headers.UnitId = this.context.unityId;
            config.headers.StudyPeriodId = this.context.studyPeriodId;
        }
        return config;
    }
}
export default Api;

import React, { useCallback, useRef, useState } from 'react';
import { useExternalClick } from '@campus/commons';
import { VideoProcessing } from '@campus/components';
import Iframe from '../../Iframe';
import MenuVideo from '../../MenuVideo';
import VideoDeletionConfirmationModal from '../../VideoDeletionConfirmationModal';
import { Container, ContainerTitle, DatePublish, ThumbnailContainer, StyledButton, Title } from './styles';
const VideoListItem = ({ item, onUpdate, onChangeMainVideo, onDelete }) => {
    const menuRef = useRef(null);
    const containerRef = useRef(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleExternalClick = useCallback(() => {
        menuRef.current?.hideDialog();
    }, []);
    useExternalClick(containerRef, handleExternalClick);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { ref: containerRef },
            React.createElement(ThumbnailContainer, null,
                item.isProcessed && (React.createElement(React.Fragment, null,
                    React.createElement(Iframe, { url: item.url, title: item.title, controls: false }),
                    React.createElement(StyledButton, { onClick: () => onChangeMainVideo(item) }))),
                !item.isProcessed && React.createElement(VideoProcessing, { small: true })),
            React.createElement(ContainerTitle, null,
                React.createElement(MenuVideo, { ref: menuRef, margin: 0, onClickDelete: () => setShowDeleteModal(true) }),
                React.createElement(Title, { value: item.title, key: item.id, onChange: (titles) => {
                        item.title = titles;
                        onUpdate(item);
                    } })),
            React.createElement(DatePublish, null, item.publishDate)),
        showDeleteModal && (React.createElement(VideoDeletionConfirmationModal, { onConfirm: () => onDelete(item.id), hideModal: () => setShowDeleteModal(false) }))));
};
export default VideoListItem;

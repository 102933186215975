import React from 'react';
import { Container, Text, Icon } from './styles';
const AddWorkCard = ({ onClick }) => {
    return (React.createElement(Container, { onClick: onClick },
        React.createElement(Icon, null),
        React.createElement(Text, null,
            "Adicionar",
            React.createElement("br", null),
            " Obra")));
};
export default AddWorkCard;

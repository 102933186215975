import React, { useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { useLiteraryWorksPageContext } from '../../context';
import ModalLiteraryWork from '../../../../pages/commons/components/ModalLiteraryWork';
import Work from '../Work';
import AddWorkCard from '../AddWorkCard';
import { WorksContainer, OpenBookIcon, AddWorkButton, PlaceholderContainer, PlaceholderText, PadlockIcon, NotAllowedContainer } from './styles';
const statusAndPlaceholders = {
    [RequestStatus.Loading]: 'Carregando obras...',
    [RequestStatus.Failed]: 'Falha ao carregar obras literárias'
};
const Works = ({ literaryWorks, requestStatus }) => {
    const { isReadOnly, refetchLiteraryWorks } = useLiteraryWorksPageContext();
    const [showModalLiteraryWork, setShowModalLiteraryWork] = useState(false);
    const handleOpenModal = () => {
        setShowModalLiteraryWork(true);
    };
    if (requestStatus === RequestStatus.NotAllowed) {
        return (React.createElement(NotAllowedContainer, null,
            React.createElement(PadlockIcon, null),
            React.createElement(PlaceholderText, null, "Voc\u00EA n\u00E3o tem acesso as obras.")));
    }
    if (requestStatus in statusAndPlaceholders) {
        return (React.createElement(PlaceholderContainer, null,
            React.createElement(PlaceholderText, null, statusAndPlaceholders[requestStatus])));
    }
    return (React.createElement(React.Fragment, null,
        literaryWorks?.length > 0 ? (React.createElement(WorksContainer, null,
            literaryWorks.map((literaryWork) => (React.createElement(Work, { key: literaryWork.id, literaryWork: literaryWork }))),
            isReadOnly ? null : React.createElement(AddWorkCard, { onClick: handleOpenModal }))) : (React.createElement(PlaceholderContainer, null,
            React.createElement(OpenBookIcon, null),
            React.createElement(PlaceholderText, null, "Nenhuma obra liter\u00E1ria adicionada"),
            isReadOnly ? null : (React.createElement(AddWorkButton, { onClick: handleOpenModal }, "Adicionar Obra")))),
        showModalLiteraryWork && (React.createElement(ModalLiteraryWork, { show: showModalLiteraryWork, onHide: () => setShowModalLiteraryWork(false), onSaved: refetchLiteraryWorks }))));
};
export default Works;

import styled from 'styled-components';
export const Container = styled.div `
  --inline-space: max(1rem, calc((100% - 971.1px) / 2));

  min-height: 100svh;

  background-color: white;

  display: grid;
  align-content: start;
  grid-template-columns:
    [full-width-start] var(--inline-space)
    [content-start] 1fr [content-end]
    var(--inline-space) [full-width-end];
`;

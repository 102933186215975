import React from 'react';
import { RequestStatus } from '@campus/commons';
import { WorkCoverImage } from '../../../commons/components';
import { useLiteraryWorkPageContext } from '../../context';
import { Container, ContentContainer, PlaceholderContainer, PlaceholderText, WorkAuthor, WorkInfosContainer, WorkName, WorkShortText } from './styles';
const statusAndPlaceholders = {
    [RequestStatus.Loading]: 'Carregando informações da obra...',
    [RequestStatus.Failed]: 'Falha ao carregar informações da obra'
};
const WorkDetails = () => {
    const { literaryWork, allowAccessToAnUnpublishedWork } = useLiteraryWorkPageContext();
    const { data, status } = literaryWork;
    if (status in statusAndPlaceholders) {
        return (React.createElement(PlaceholderContainer, null,
            React.createElement(PlaceholderText, null, statusAndPlaceholders[status])));
    }
    if (!data) {
        return (React.createElement(PlaceholderContainer, null,
            React.createElement(PlaceholderText, null, "As informa\u00E7\u00F5es vinculadas \u00E0 esta obra n\u00E3o puderam ser encontradas.")));
    }
    return (React.createElement(Container, null,
        React.createElement(ContentContainer, null,
            React.createElement(WorkCoverImage, { literaryWork: data, doNotBlurIfWorkIsUnpublished: allowAccessToAnUnpublishedWork }),
            React.createElement(WorkInfosContainer, null,
                React.createElement(WorkName, null, data.name),
                React.createElement(WorkAuthor, null, data.author),
                React.createElement(WorkShortText, null, data.shortText)))));
};
export default WorkDetails;

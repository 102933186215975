import styled from 'styled-components';
import { Modal as BaseModal } from '@campus/components';
import { commonOptionsBoxStyles } from '../commons/styles/questionRelated';
export const Modal = styled(BaseModal) `
  .question-editable__header {
    padding: 16px 16px 7px 16px;
  }

  .question-editable__content {
    padding: 0 16px 16px 16px;
  }

  .question-editable__options-box {
    padding: 14px 16px;

    ${commonOptionsBoxStyles}
  }

  .question-editable__open-question-box {
    display: block;
    padding: 10px 16px 16px 16px;
  }

  .question-editable__comment-box {
    padding: 10px 16px 16px 16px;
  }
`;

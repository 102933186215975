import styled from 'styled-components';
import { Placeholder } from '../../../commons/components';
export const Container = styled.div `
  grid-column: content;

  padding: 45px 0;

  display: flex;
  gap: 14px;
  align-items: flex-start;

  & > :first-child {
    flex: 1 1 auto;
  }

  & > :last-child {
    flex: 0 1 600px;
    align-self: stretch;
  }
`;
export const PlaceholderContainer = styled(Placeholder.Container) `
  grid-column: content;

  min-height: 100px;
`;
export const PlaceholderText = styled(Placeholder.Text) ``;

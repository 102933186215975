import React, { useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useExternalClick } from '@campus/commons';
import { useLiteraryWorksPageContext } from '../../context';
import routes from '../../../routes';
import { WorkCoverImage } from '../../../commons/components';
import WorkMenu from '../WorkMenu';
import WorkRelatedVestibulars from '../WorkRelatedVestibulars';
import { classNames } from './classNames';
import { Container, NotPublishedMessageContainer, NotPublishedMessageText, Title } from './styles';
const Work = ({ literaryWork }) => {
    const { isReadOnly, allowAccessToAnUnpublishedWork } = useLiteraryWorksPageContext();
    const menuRef = useRef(null);
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const coverImageIsClickable = allowAccessToAnUnpublishedWork || literaryWork.published;
    function onCoverImageClick() {
        if (menuRef.current?.isDialogBeingShown()) {
            menuRef.current.hideDialog();
        }
        else {
            navigate(routes.dynamic.literaryWork(literaryWork.id));
        }
    }
    const handleExternalClick = useCallback(() => {
        menuRef.current?.hideDialog();
    }, []);
    useExternalClick(containerRef, handleExternalClick);
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(WorkCoverImage, { literaryWork: literaryWork, ...(coverImageIsClickable ? { onClick: onCoverImageClick } : {}) },
            isReadOnly ? null : (React.createElement(WorkMenu, { ref: menuRef, className: classNames.workMenu, literaryWork: literaryWork })),
            literaryWork.published ? null : (React.createElement(NotPublishedMessageContainer, null,
                React.createElement(NotPublishedMessageText, null, "EM BREVE")))),
        React.createElement(Title, null, literaryWork.name),
        React.createElement(WorkRelatedVestibulars, { literaryWork: literaryWork })));
};
export default Work;

import styled from 'styled-components';
import { QuestionCard } from '@campus/content-items';
import { Box } from '../commons/styles/basics';
import { commonCardStyles, commonCommentBoxStyles, commonEditorFontStyles, commonOptionsBoxStyles } from '../commons/styles/questionRelated';
export const Container = styled(Box) ``;
export const Question = styled(QuestionCard) `
  ${commonCardStyles}

  .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected {
    outline: none;
  }

  .question-card__item-list .ck.ck-editor__editable_inline,
  .question-card__open-question-box .ck.ck-editor__editable_inline {
    ${commonEditorFontStyles}
  }

  .question-card__options-box {
    ${commonOptionsBoxStyles}
  }

  .question-card__comment-box {
    ${commonCommentBoxStyles}
  }

  .question-card__open-question-editor .ck.ck-content.ck-editor__editable {
    min-height: 0;
  }
`;

import React from 'react';
import { Page } from '../commons/components';
import { PageContextProvider } from './context';
import { PageDescription, Works, PageSelector } from './components';
import { useFetchLiteraryWorks } from './hooks';
import { ContentContainer } from './styles';
const LiteraryWorks = ({ title, isReadOnly, allowAccessToAnUnpublishedWork }) => {
    const { data, requestStatus, numberOfPages, pageIndex, setPageIndex, fetchLiteraryWorks } = useFetchLiteraryWorks({ pageSize: isReadOnly ? 12 : 11 });
    return (React.createElement(Page, { pageTitle: title },
        React.createElement(ContentContainer, null,
            React.createElement(PageContextProvider, { isReadOnly: isReadOnly ?? false, refetchLiteraryWorks: fetchLiteraryWorks, allowAccessToAnUnpublishedWork: allowAccessToAnUnpublishedWork ?? false },
                React.createElement(PageDescription, null),
                React.createElement(Works, { literaryWorks: data, requestStatus: requestStatus }),
                data?.length > 0 && numberOfPages > 1 ? (React.createElement(PageSelector, { numberOfPages: numberOfPages, pageIndex: pageIndex, setPageIndex: setPageIndex })) : null))));
};
export default LiteraryWorks;

import styled from 'styled-components';
import { Checkbox, Textarea, Modal, Button, Input, TagsInput, DropImage } from '@campus/components';
export const StyledModal = styled(Modal) `
  .modal-title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 28px !important;
    line-height: 130%;
    color: ${(props) => props.theme.colors.gray1};
  }

  .modal-header {
    padding-bottom: 17px !important;
    border-bottom: 1px solid #bdbdbd !important;
  }
`;
export const StyledModalBody = styled(Modal.Body) `
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  padding: 0px;
  margin: 18px 0px 12px 0px;
`;
export const StyledLeftCol = styled.div `
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  padding: 14px;
  gap: 14px;
  width: 70%;

  @media ${(props) => props.theme.mediaQuery.tabletDown} {
    width: 100%;
  }
`;
export const StyledRightCol = styled.div `
  display: flex;
  flex-direction: column;
  height: 290px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  padding: 19px;
  margin-left: 14px;
  width: 28%;

  @media ${(props) => props.theme.mediaQuery.tabletDown} {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const StyledTextarea = styled(Textarea) `
  height: 239px;
  resize: none;

  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray4};

  padding: 10px 13px;

  &,
  &::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.gray4};
  }
`;
export const StyledRow = styled.div `
  display: flex;
  flex-direction: row;
  gap: 14px;

  @media ${(props) => props.theme.mediaQuery.tabletDown} {
    flex-direction: column;
  }
`;
export const StyledBasicButton = styled(Button) `
  height: 40px;
  border-radius: 5px;
  font-size: 17.5px;
  font-weight: 500;

  ${(props) => {
    if (props.disabled) {
        return 'opacity: 0.8;';
    }
    return `
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    `;
}}
`;
export const StyledCancelButton = styled(StyledBasicButton) `
  padding: 0 15px;

  color: ${(props) => props.theme.colors.gray4} !important;
  line-height: 110%;
  background-color: white !important;

  border: 1px solid ${(props) => props.theme.colors.gray4} !important;
`;
export const StyledButton = styled(StyledBasicButton) `
  padding: 0 16px;
  width: 167px;

  color: white !important;
  background-color: ${(props) => props.theme.colors.blue1} !important;
  opacity: 1 !important;

  border: 1px solid ${(props) => props.theme.colors.blue1} !important;
`;
export const StyledCheckbox = styled(Checkbox) `
  label {
    flex: revert;

    margin-top: auto;

    cursor: pointer;

    font-size: 14px;
    font-weight: 600;
    line-height: 110%;

    color: ${(props) => props.theme.colors.gray1};
  }
`;
export const StyledInput = styled(Input) `
  label {
    line-height: 130%;
  }

  input,
  input::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  input {
    height: 40px;
    padding: 8px 13px;
  }

  input::placeholder {
    color: ${(props) => props.theme.colors.gray4};
  }
`;
export const StyledTagsInput = styled(TagsInput) `
  .tags-input__tags {
    height: 41px;
  }

  .tags-input__tag,
  .tags-input__input,
  .tags-input__add-tag {
    font-weight: 600;
    line-height: 110%;
  }

  .tags-input__input {
    color: ${(props) => props.theme.colors.gray2};
  }
`;
export const StyledDropImage = styled(DropImage) `
  .drop-image__image {
    background-position: center;
  }
`;

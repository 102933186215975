import React from 'react';
import { showErrorsUsingToast } from '@campus/commons';
import { ContainerStyled } from './styles';
import { useLiteraryWorkPageContext } from '../../../context';
import { useVideoManager } from '../hooks/useVideoManager';
import VideoUpload from './VideoUpload';
import { MainVideo } from './MainVideo';
import { VideoList } from './VideoList';
const EditableVideoList = ({ videoAnalisysItems }) => {
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { items, addVideo, removeVideo, updateVideo, mainVideo, updateMainVideo } = useVideoManager({
        items: videoAnalisysItems,
        literaryWorkId,
        onError: showErrorsUsingToast
    });
    if (!items || items.length === 0 || !mainVideo) {
        return (React.createElement(ContainerStyled, null,
            React.createElement(VideoUpload, { addVideoToList: addVideo })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ContainerStyled, null,
            React.createElement(VideoUpload, { addVideoToList: addVideo })),
        React.createElement(MainVideo, { video: mainVideo, onUpdate: updateVideo, onDelete: removeVideo }),
        React.createElement(VideoList, { videos: items, onChangeMainVideo: updateMainVideo, onDelete: removeVideo, onUpdate: updateVideo })));
};
export default EditableVideoList;

import { showNetworkErrorErrorsUsingToast, Upload, UploadProvider } from '@campus/commons';
import { uploadForLiteraryWork } from '../../../services';
export function useUploadForLiteraryWork({ literaryWorkId }) {
    function createUploadInstance(type, uploadProvider) {
        return new Upload('literary-work', {
            type,
            variantId: literaryWorkId,
            provider: uploadProvider ?? UploadProvider.Azure,
            howToUpload(params) {
                return uploadForLiteraryWork({
                    ...params,
                    literaryWorkId,
                    whatToUpload: params.type
                });
            },
            onError(error) {
                showNetworkErrorErrorsUsingToast(error);
                throw error.error;
            }
        });
    }
    return { createUploadInstance };
}
